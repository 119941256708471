import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {IonicStorageModule} from '@ionic/storage';
import {NativeGeocoder} from '@ionic-native/native-geocoder/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {Camera} from '@ionic-native/camera/ngx';
// import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import {Crop} from '@ionic-native/crop/ngx';
// import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {ImageModalPageModule} from 'src/app/pages/image-modal/image-modal.module';
import {File} from '@ionic-native/file/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
//import { SignaturePadModule } from 'angular2-signaturepad';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {FirebaseAnalytics} from '@ionic-native/firebase-analytics/ngx';
import {EmailComposer} from '@ionic-native/email-composer/ngx';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {FirebaseDynamicLinks} from '@ionic-native/firebase-dynamic-links/ngx';
import {AppAvailability} from '@ionic-native/app-availability/ngx';
import {LoggerService} from './services/logger.service';
import {FullCalendarModule} from '@fullcalendar/angular';
import {DatePipe} from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      swipeBackEnabled: false,
      mode: 'ios',
    }),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: '__myCrewMobile',
      driverOrder: ['indexeddb', 'websql', 'localstorage'],
    }),
    ImageModalPageModule,
    FullCalendarModule,
  ],
  providers: [
    AppAvailability,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    Geolocation,
    EmailComposer,
    FirebaseDynamicLinks,
    NativeGeocoder,
    ScreenOrientation,
    InAppBrowser,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AppVersion,
    CallNumber,
    Camera,
    Crop,
    FirebaseAnalytics,
    // OneSignal,
    File,
    SplashScreen,
    Deeplinks,
    AndroidPermissions,
    LoggerService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
