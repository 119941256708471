import {NgModule} from '@angular/core';
import {NavigationEnd, PreloadAllModules, Router, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import posthog from 'posthog-js';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/welcome',
  //   pathMatch: 'full'
  // },
  {path: '', redirectTo: 'tabs/home', pathMatch: 'full'},
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'mechanic-welcome',
    loadChildren: () =>
      import('./pages/mechanic-welcome/mechanic-welcome.module').then(
        (m) => m.MechanicWelcomePageModule
      ),
  },
  // {
  //   path: 'notification-settings',
  //   loadChildren: () => import('./pages/profile/notification-settings/notification-settings.module').then( m => m.NotificationSettingsPageModule)
  // },

  {
    path: 'mechanic-login',
    loadChildren: () =>
      import('./pages/mechanic-login/mechanic-login.module').then((m) => m.MechanicLoginPageModule),
  },
  {
    path: 'mechanic-signup',
    loadChildren: () =>
      import('./pages/mechanic-signup/mechanic-signup.module').then(
        (m) => m.MechanicSignupPageModule
      ),
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth-routing.module').then((m) => m.AuthPageRoutingModule),
  },
  {
    path: 'new-profile-estimat',
    loadChildren: () =>
      import('./pages/new-profile-estimat/new-profile-estimat.module').then(
        (m) => m.NewProfileEstimatPageModule
      ),
  },
  {
    path: 'signature-pad',
    loadChildren: () =>
      import('./pages/signature-pad/signature-pad.module').then((m) => m.SignaturePadPageModule),
  },
  {
    path: 'select-mechanic',
    loadChildren: () =>
      import('./pages/select-mechanic/select-mechanic.module').then(
        (m) => m.SelectMechanicPageModule
      ),
  },
  {
    path: 'splashscreen',
    loadChildren: () =>
      import('./pages/splashscreen/splashscreen.module').then((m) => m.SplashscreenPageModule),
  },
  {
    path: 'estimate-revision-request',
    loadChildren: () =>
      import('./pages/estimate-revision-request/estimate-revision-request.module').then(
        (m) => m.EstimateRevisionRequestPageModule
      ),
  },
  {
    // path: 'buildquotelogin/:userId',
    path: 'buildquotelogin',
    loadChildren: () =>
      import('./pages/buildquotelogin/build-quotelogin.module').then(
        (m) => m.BuildQuoteloginPageModule
      ),
  },
  {
    path: 'select-mechanic',
    loadChildren: () =>
      import('./pages/select-mechanic/select-mechanic.module').then(
        (m) => m.SelectMechanicPageModule
      ),
  },

  // {
  //   path: 'declined-repair-estimate',
  //   loadChildren: () => import('./pages/declined-repair-estimate/declined-repair-estimate.module').then( m => m.DeclinedRepairEstimatePageModule)
  // },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        posthog.capture('$pageview');
      }
    });
  }
}
