import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.includes('AIzaSyAeJNI9Qx_I0gcG7AdNth6XXdtNl1F08ZM')) {
      return next.handle(req);
    }
    let url = new URL(req.url);
    if (url.host == environment.LEGACY_HOST) {
      return this.authService.getLegacyAuthToken().pipe(
        switchMap((token) => {
          if (!token) {
            return next.handle(req);
          }
          const newReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next.handle(newReq);
        })
      );
    }

    if (url.host == environment.HOST && url.pathname != '/auth/login') {
      return this.authService.getTokenFromStorage().pipe(
        switchMap((token) => {
          const newReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next.handle(newReq).pipe(
            catchError((err) => {
              return this.authService.getTokenFromApi().pipe(
                switchMap((newToken) => {
                  const newReq = req.clone({
                    setHeaders: {
                      Authorization: `Bearer ${newToken}`,
                    },
                  });
                  return next.handle(newReq);
                })
              );
            })
          );
        })
      );
    }

    return next.handle(req);
  }
}
