import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import {HttpClient, HttpHeaders} from '@angular/common/http';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import oneSignal from 'onesignal-cordova-plugin';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {LegacyHttpService} from './legacy-http.service';
import {HttpService} from './http.service';
import {NavController, AlertController} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authenticationState = new BehaviorSubject(false);
  invoiceId = new BehaviorSubject(null);
  orderState = new BehaviorSubject({});
  userInfo = new BehaviorSubject({});
  cardData = new BehaviorSubject({});
  invoiceInfo = new BehaviorSubject([]);

  mechanicData = new BehaviorSubject({});
  authUser: any;
  reducedUser: any;
  isLoggedIn = false;
  token: any;
  serviceFee: any;
  companyName: any;
  diagnosFee: any;
  notificationsUnreadCount = 0;
  isTabSelected = 0;
  signUpId = new BehaviorSubject(0);
  email: any;
  isRecovery = 0;
  id: any;
  res_data: any;
  currentTimeZone: any;
  googltrapiUrl =
    'https://www.googleapis.com/calendar/v3/calendars/turkish__tr%40holiday.calendar.google.com/events?key=AIzaSyAeJNI9Qx_I0gcG7AdNth6XXdtNl1F08ZM';

  constructor(
    private platform: Platform,
    private router: Router,
    public storage: Storage,
    private http: HttpClient,
    // private oneSignal: OneSignalPlugin,
    private LegacyHttpService: LegacyHttpService,
    private navCtrl: NavController
  ) {
    this.platform.ready().then(async () => {
      this.currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.getUserAsync();
    });
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  // Function to convert a local time to UTC
  convertToLocalUTC(localTime: string): string {
    return new Date(localTime.replace(' ', 'T') + 'Z').toLocaleString('en-US', {
      timeZone: this.currentTimeZone,
    });
  }
  getUserAsync() {
    Promise.all([
      this.storage.get('token'),
      this.storage.get('user'),
      this.storage.get('newtoken'),
      this.storage.get('reduced_user'),
    ]).then((res) => {
      const [token, user, newToken, reducedUser] = res;
      if (token && user && newToken && reducedUser) {
        this.isLoggedIn = true;
        this.token = token;
        this.authUser = user;
        this.authenticationState.next(true);
        this.userInfo.next(user);
      }
    });
  }

  /**
   * Get current auth token
   */
  getLegacyAuthToken(): Observable<string> {
    return from(this.storage.get('token'));
  }

  getTokenFromStorage(): Observable<any> {
    let token = from(this.storage.get('newtoken'));

    return token.pipe(
      switchMap((token) => {
        return of(token);
      })
    );
  }

  getTokenFromApi(): Observable<string> {
    let reducedUser = from(this.storage.get('reduced_user'));
    return reducedUser.pipe(
      switchMap((reducedUser) => {
        if (reducedUser) {
          let loginBody = {
            email: reducedUser.email,
            user_group: reducedUser.user_group,
            password: reducedUser.password,
          };
          return this.http.post(environment.API_URL + 'auth/login', loginBody).pipe(
            map((res: any) => {
              this.storage.set('newtoken', res.token);
              return res.token;
            })
          );
        }
      })
    );
  }

  doLogin(data) {
    this.http
      .post(environment.API_URL + 'auth/login', data)
      .subscribe((val: any) => this.storage.set('newtoken', val.token));

    return this.http.post(environment.LEGACY_API_URL + 'auth/login', data).pipe(
      tap((res: any) => {
        this.res_data = res.message;
        this.storage.set('msg_login_err', this.res_data);
        this.storage.set('user_info', res.data);
        this.reducedUser = {
          email: data.email,
          password: data.password,
          user_group: res.data.user_group,
        };

        if (res && res.status === true) {
          if (res.is_verified == 1) {
            this.updateUserData(res);
          } else {
            this.id = res.data.id;
            this.signUpId.next(res.data.id);
            this.userInfo.next(res.data);
            this.navCtrl.navigateForward(['/signup/confirm-email']);
          }
        }
      })
    );
  }

  dobuildLogin(data, id: any) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/login', data).pipe(
      tap((res: any) => {
        this.reducedUser = {
          email: data.email,
          password: data.password,
          user_group: res.user_group,
        };
        this.res_data = res.message;
        this.storage.set('msg_login_err', this.res_data);
        this.storage.set('user_info', res.data);
        if (res && res.status === true) {
          this.setStroage(res, id);
          // this.updateUserData(res);
        }
        // this.router.navigate(['tabs/home']);
      })
    );
  }

  async setStroage(data, id) {
    this.isLoggedIn = true;
    this.token = data.token;
    this.authUser = data.data;
    await this.storage.set('reduced_user', this.reducedUser);
    await this.storage.set('token', this.token);
    await this.storage.set('user', this.authUser);
    await this.storage.set('USER_GROUP', data.data.user_group);
    this.userInfo.next(this.authUser);
    this.authenticationState.next(true);
    this.invoiceId.next(id);
  }

  async updateUserData(data: any) {
    this.isLoggedIn = true;
    this.token = data.token;
    this.authUser = data.data;
    await this.storage.set('reduced_user', this.reducedUser);
    await this.storage.set('token', this.token);
    await this.storage.set('user', this.authUser);
    await this.storage.set('USER_GROUP', data.data.user_group);
    this.authenticationState.next(true);
    this.userInfo.next(this.authUser);
    this.authenticationState.next(true);
  }

  register(data) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/register/', data);
  }

  verifyOtp(data) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/verify_otp', data);
  }

  // tslint:disable-next-line:variable-name
  resendOtp(user_id) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/resend_verification', {
      user_id,
    });
  }

  // oneSignalInit(){}
  oneSignalInit() {
    if (!this.platform.is('mobileweb') && !this.platform.is('desktop')) {
      oneSignal.setExternalUserId(this.authUser.email);
      oneSignal.setAppId(environment.ONESIGNAL_APPID);
      // Prompts the user for notification permissions.
      //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.

      oneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {});

      oneSignal.disablePush(false);
      oneSignal.sendTags({
        user_id: this.authUser.user_id,
        user_group: this.authUser.user_group,
        email: this.authUser.email,
        user_status: this.authUser.status,
        created_date: this.authUser.created_date,
        platform: this.authUser.platform,
      });

      oneSignal.setEmail(this.authUser.email);

      oneSignal.setNotificationOpenedHandler((res) => {
        const data = res.notification.additionalData;
        this.openByNotification(data);
      });

      this.storage.get('user').then((user) => {
        if (user) {
          oneSignal.getDeviceState((deviceState) => {
            if (deviceState && deviceState.userId) {
              this.LegacyHttpService.post('user/save_token', deviceState).subscribe((res) => {});
            }
          });
        }
      });
    }
  }

  private openByNotification(data: any) {
    const href =
      this.authUser && this.authUser.user_group == 3
        ? 'alerts/order-info'
        : 'mechanic-orders/order-detail';
    if (data) {
      switch (data.action) {
        case 'repair_estimate':
          this.router.navigate(['/tabs/repair-estimate', data.job_id]);
          break;
        case 'completed':
          this.router.navigate(['/tabs/home/feedback', {orderid: data.job_id}]);
          break;
        case 'cancel_job':
          this.router.navigate(['/tabs/home']);
          break;
        case 'chat':
          const params = {
            receiver_id: data.receiver_id,
            status: 'Accepted',
          };
          this.router.navigateByUrl('/tabs/chat/' + data.job_id, {
            state: params,
          });
          break;
        default:
          this.router.navigate([`/tabs/${href}`, data.job_id]);
          break;
      }
    } else {
      this.router.navigate(['/tabs/home']);
    }
  }

  saveBilling(data) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/save_billing', data);
  }

  updateBilling(id, data) {
    return this.http.post(environment.LEGACY_API_URL + `auth/update_billing/${id}`, data);
  }

  submitSurvey(data) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/survey', data);
  }

  singUpRegister(data) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/next_register', data);
  }

  securityQuestionAnswer(data) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/security_question_answer', data);
  }

  changePassword(data) {
    return this.http.post(environment.LEGACY_API_URL + 'mechanic/change_password', data);
  }

  getMechanicBussinessInfo() {
    return this.http.get(environment.LEGACY_API_URL + 'auth/get_mechanic_bussiness_info');
  }

  getSecurityQuestionList() {
    return this.http.get(environment.LEGACY_API_URL + 'auth/get_security_question_list');
  }

  getAvailability() {
    return this.http.get(environment.LEGACY_API_URL + 'mechanic/availability');
  }

  contactUs(data) {
    return this.http.post(environment.LEGACY_API_URL + 'user/contacts', data);
  }

  notificationSetting(data) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/notification_setting', data);
  }

  getNotificationSetting(data) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/get_notification_setting', data);
  }

  saveAvailability(data) {
    return this.http.post(environment.LEGACY_API_URL + 'mechanic/availability', data);
  }

  myCards() {
    return this.http.get(environment.LEGACY_API_URL + 'auth/my_cards');
  }

  deleteCard(data) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/card_delete', data);
  }

  cardInfo(id) {
    return this.http.get(environment.LEGACY_API_URL + `auth/card_info/${id}`);
  }

  mechanicNotifications() {
    return this.http.get(environment.LEGACY_API_URL + `mechanic/mechanic_notification`);
  }

  consumerNotifications() {
    return this.http.get(environment.LEGACY_API_URL + `user/user_notification`).pipe(
      tap((res: any) => {
        if (res) {
          this.diagnosFee = res.diagnos_fee;
          this.serviceFee = res.service_fee;
          this.companyName = res.company_name;
          if (res.status === true) {
            this.notificationsUnreadCount = res.data.filter((n) => n.read == '0').length;
          }
        }
      })
    );
  }

  markReadNotificaion() {
    return this.LegacyHttpService.post('user/seen_job', {});
  }

  singleNotificaion(id) {
    const form = new FormData();
    form.append('notification_id', id);
    return this.LegacyHttpService.post('user/seen_job_single', form);
  }

  getUserInvoice(para: any) {
    return this.http.post(environment.LEGACY_API_URL + 'user/user_invoice', para);
  }

  getTermsAndCondition() {
    return this.http.get(environment.LEGACY_API_URL + 'auth/get_terms_and_condition');
  }
  getSurveyList() {
    return this.http.get(environment.LEGACY_API_URL + 'auth/get_survey_list');
  }

  getNearestMechanic(data) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/get_nearest_mechanic', data);
  }

  updateProfile(data: any) {
    return this.LegacyHttpService.post('user/update_profile', data);
  }
  updateMachenicProfile(data: any, token: any) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    // Configure the HTTP options
    const httpOptions = {
      headers: headers,
    };
    return this.LegacyHttpService.post('user/update_profile', data, httpOptions);
  }

  update_MachenicProfile(data: any) {
    const headers = new HttpHeaders({
      // Authorization: `Bearer ${token}`,
    });

    // Configure the HTTP options
    const httpOptions = {
      headers: headers,
    };
    return this.LegacyHttpService.post('auth/mechanic_update_profile', data, httpOptions);
  }

  updateMechanicBusinessProfile(data: any) {
    return this.LegacyHttpService.post('mechanic/update_profile', data);
  }

  getMechanicProfile() {
    return this.LegacyHttpService.post('mechanic/get_profile', {});
  }

  getMechanic_Profile(data) {
    return this.LegacyHttpService.post('auth/mechanic_get_profile', data);
  }

  socialLogin(data: any) {
    return this.LegacyHttpService.post('auth/oauth', data).pipe(
      tap((res: any) => {
        if (res && res.status === true) {
          this.updateUserData(res);
        }
      })
    );
  }

  cancelOrderByConsumer(order_id: any) {
    const data = {
      book_request_id: order_id,
      reason: '',
      manual_reason: 'I changed my mind',
    };
    return this.LegacyHttpService.post('user/cancel_order', data);
  }

  resetPasswordOtp(data: any) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/resend', data);
  }

  forgotPassword(data: any) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/forgot_password', data);
  }

  logout() {
    // return this.http.get(environment.LEGACY_API_URL + `auth/card_info/${id}`);
    return this.http.get(environment.LEGACY_API_URL + 'user/logout', {});
  }
  deleteAccount(data: any) {
    return this.http.post(environment.LEGACY_API_URL + 'Auth/delete_account', data);
  }
  deActivateAccount(data: any) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/deactivate_account', {
      status: 'active',
    });
  }

  updateServicePartDetail(data, authToken) {
    return this.LegacyHttpService.post('user/update_service', data);
  }

  build_QuoteLogin(data) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/build_quote_update_profile', data);
  }

  searchEmail(data) {
    return this.http.post(environment.LEGACY_API_URL + 'auth/get_user_data', data);
  }

  googleApi() {
    return this.http.get(this.googltrapiUrl);
  }
}
