import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {Logtail} from '@logtail/browser';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  logger: Logtail;
  constructor(private alertController: AlertController) {
    this.logger = new Logtail(environment.LOGTAIL);
  }

  logError(message: string) {
    this.logger.error(message);
    this.logger.flush();
  }

  loginformation(message: string) {
    this.logger.info(message);
    this.logger.flush();
  }

  logErrorAndAlert(error: any): void {
    this.logger.error('An error occurred:', error);
    this.logger.flush();
    this.presentAlert();
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Error!',
      message: 'An error occurred. Please try again later.',
      buttons: ['OK'],
    });

    await alert.present();
  }
}
