import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {throwError, Observable} from 'rxjs';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http';
import {map, catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LegacyHttpService {
  constructor(private http: HttpClient) {}

  post(url: string, data: any, options?: any) {
    url = environment.LEGACY_API_URL + url;
    return this.http.post(url, data, options).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  patch(url: string, data: any) {
    url = environment.LEGACY_API_URL + url;
    return this.http.patch(url, data).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  postWithToken(url: string, data: any, authToken?: any): Observable<any> {
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      }),
    };
    url = environment.LEGACY_API_URL + url;
    return this.http.post(`${url} `, data, httpOptions).pipe(
      map((res) => res),
      catchError((err) => throwError(err))
    );
  }

  get(url: string, params?: any) {
    return this.http.get(environment.LEGACY_API_URL + url, {params}).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  put(url: string, data: any) {
    url = environment.LEGACY_API_URL + url;
    return this.http.put(url, data).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  delete(url: string, data: any) {
    url = environment.LEGACY_API_URL + url;
    return this.http.delete(url, data).pipe(
      tap((data) => {
        return data;
      })
    );
  }
}
