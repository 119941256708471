import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppService } from '../../services/app.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.page.html',
  styleUrls: ['./image-modal.page.scss']
})
export class ImageModalPage implements OnInit {
  @Input() images = [];

  constructor(private modalCtrl: ModalController, public appService: AppService,private firebaseAnalytics: FirebaseAnalytics,) {
    this.firebaseAnalytics.logEvent('image-modal', { content_type: 'speaker', page: 'image-modal'})
  }

  ngOnInit() {}

  dismissModal() {
    this.modalCtrl.dismiss();
  }
}
