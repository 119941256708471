import {Injectable} from '@angular/core';
import {Platform, LoadingController, AlertController, ToastController} from '@ionic/angular';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private activeTabSubject = new BehaviorSubject<string>('pending');
  activeTab$ = this.activeTabSubject.asObservable();
  loader: any;
  href: string;
  regEx = {
    onlyNumber: '^[0-9]{10}$',
  };
  imagePath = '';
  badgePath = '';
  avatar = '/assets/images/avatar.png';
  orderTab = '';
  uploadPath = '';
  isLoading = false;
  isPageRefresh: boolean = false;

  constructor(
    private platform: Platform,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController
  ) {
    this.imagePath = `${environment.BASE_URL}uploads/profile_image/`;
    this.badgePath = `${environment.BASE_URL}uploads/badges/`;
    this.uploadPath = `${environment.BASE_URL}uploads/`;
  }

  setActiveTab(tab: string) {
    this.activeTabSubject.next(tab);
  }

  async presentLoader(msg = '') {
    this.isLoading = true;

    return await this.loadingCtrl
      .create({spinner: 'bubbles', cssClass: 'custom-loading'})
      .then((a) => {
        a.present().then(() => {
          if (!this.isLoading) {
            a.dismiss().then(() => {});
          }
        });
      });
  }

  async dismissLoader() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => {});
  }

  async presentMessageForFiveSecondsToast(message: any, duration = 5000) {
    if (message === '') {
      message = 'Something went wrong!';
    }
    const toast = await this.toastCtrl.create({
      message,
      duration,
      cssClass: 'toast-mechanic-not-found',
      position: 'middle',
      color: 'danger',
    });

    toast.present();
  }

  async presentToast(message: any, duration = 2000) {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      position: 'top',
      color: 'dark',
    });
    return await toast.present();
  }

  async presentAlert(header, subHeader, message = '', button = 'OK') {
    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons: [button],
    });
    await alert.present();
  }

  async presentErrorToast(message = '', duration = 2000) {
    if (message === '') {
      message = 'Something went wrong!';
    }
    const toast = await this.toastCtrl.create({
      message,
      duration,
      position: 'middle',
      color: 'danger',
    });
    toast.present();
  }

  getMonthsList() {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  }

  getYearsList() {
    // tslint:disable-next-line:one-variable-per-declaration
    const currentYear = new Date().getFullYear(),
      years = [];
    for (let i = currentYear; i < currentYear + 15; i++) {
      years.push(i);
    }
    return years;
  }

  toFormData(formValue: any) {
    const formData = new FormData();
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      if (typeof value === 'object') {
        [...value].map((item) => {
          formData.append(`${key}[]`, item);
        });
      } else {
        formData.append(key, value);
      }
    }
    return formData;
  }

  userImage(user) {
    return user.profile_image ? `${this.imagePath}${user.id}/${user.profile_image}` : this.avatar;
  }

  mechanicImage(mechanic) {
    return mechanic.profile_image
      ? `${this.imagePath}${mechanic.id}/${mechanic.profile_image}`
      : this.avatar;
  }

  getBadgesPath() {
    return `${this.badgePath}`;
  }

  openMapsApp(lat, long) {
    if (this.platform.is('android')) {
      window.location.href = `geo:${lat},${long}`;
    } else {
      window.location.href = `https://maps.apple.com/?q=${lat},${long}`;
    }
  }

  getStatusText(order_status: string, work_invoice: string) {
    let status_label = 'Pending';
    const status = parseInt(order_status);
    switch (status) {
      case 1:
      case 12:
      case 13:
        status_label = 'Pending';
        break;
      case 2:
        status_label = 'Accepted';
        break;
      case 3:
        status_label = 'Cancelled';
        break;
      case 4:
        status_label = 'Completed';
        break;
      case 9:
        status_label = parseInt(work_invoice) == 3 ? 'Declined' : 'T & C Rejected by Customer';
        break;
      case 5:
        status_label = 'On site & working';
        break;
      case 16:
        status_label = 'Repair Scheduled';
        break;
      case 6:
        status_label = 'On the way';
        break;
      case 15:
        status_label = 'Picking up parts';
        break;
      case 11:
        status_label = 'Build Estimate';
        break;
      case 18:
        status_label = 'Out of Scope';
        break;
      case 17:
        status_label = 'Paused & Rescheduled Repair';
        break;
      default:
        break;
    }

    return status_label;
  }

  getTitlebaseOnStatusText(order_status: string, work_invoice: string) {
    let status_label = '';
    const status = parseInt(order_status);
    switch (status) {
      case 1:
      case 12:
      case 13:
        status_label = 'Repair Order is Pending';
        break;
      case 2:
        status_label = 'Mechanic has Accepted';
        break;
      case 3:
        status_label = 'Repair Order is Cancelled';
        break;
      case 4:
        status_label = 'Repair Order is Completed';
        break;
      case 9:
        status_label =
          parseInt(work_invoice) == 3 ? 'Customer Declined Estimate' : 'Customer Approved Estimate';
        break;
      case 5:
        status_label = 'Mechanic is On-Site & Working';
        break;
      case 16:
        status_label = 'Mechanic has Repair Scheduled';
        break;
      case 6:
        status_label = 'Mechanic is On the way';
        break;
      case 15:
        status_label = 'Mechanic is Picking up parts';
        break;
      case 11:
        status_label = 'Mechanic sent Repair Estimate';
        break;
      case 17:
        status_label = 'Mechanic has Paused & Rescheduled Repair';
        break;
      default:
        break;
    }

    return status_label;
  }

  pad(num, size) {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }
}
